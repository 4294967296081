// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-birmovanci-tsx": () => import("./../../../src/pages/birmovanci.tsx" /* webpackChunkName: "component---src-pages-birmovanci-tsx" */),
  "component---src-pages-den-farskej-rodiny-tsx": () => import("./../../../src/pages/den-farskej-rodiny.tsx" /* webpackChunkName: "component---src-pages-den-farskej-rodiny-tsx" */),
  "component---src-pages-farnost-cirkevna-skola-tsx": () => import("./../../../src/pages/farnost/cirkevna-skola.tsx" /* webpackChunkName: "component---src-pages-farnost-cirkevna-skola-tsx" */),
  "component---src-pages-farnost-farske-oznamy-tsx": () => import("./../../../src/pages/farnost/farske-oznamy.tsx" /* webpackChunkName: "component---src-pages-farnost-farske-oznamy-tsx" */),
  "component---src-pages-farnost-galeria-tsx": () => import("./../../../src/pages/farnost/galeria.tsx" /* webpackChunkName: "component---src-pages-farnost-galeria-tsx" */),
  "component---src-pages-farnost-kalendar-akcii-tsx": () => import("./../../../src/pages/farnost/kalendar-akcii.tsx" /* webpackChunkName: "component---src-pages-farnost-kalendar-akcii-tsx" */),
  "component---src-pages-farnost-kostol-sv-alzbety-tsx": () => import("./../../../src/pages/farnost/kostol-sv-alzbety.tsx" /* webpackChunkName: "component---src-pages-farnost-kostol-sv-alzbety-tsx" */),
  "component---src-pages-farnost-kostol-sv-jana-pavla-v-lieskovci-tsx": () => import("./../../../src/pages/farnost/kostol-sv-jana-pavla-v-lieskovci.tsx" /* webpackChunkName: "component---src-pages-farnost-kostol-sv-jana-pavla-v-lieskovci-tsx" */),
  "component---src-pages-farnost-kostol-sv-matusa-v-zolnej-tsx": () => import("./../../../src/pages/farnost/kostol-sv-matusa-v-zolnej.tsx" /* webpackChunkName: "component---src-pages-farnost-kostol-sv-matusa-v-zolnej-tsx" */),
  "component---src-pages-farnost-nase-spolocenstvo-index-tsx": () => import("./../../../src/pages/farnost/nase-spolocenstvo/index.tsx" /* webpackChunkName: "component---src-pages-farnost-nase-spolocenstvo-index-tsx" */),
  "component---src-pages-farnost-nase-spolocenstvo-strapi-articles-slug-tsx": () => import("./../../../src/pages/farnost/nase-spolocenstvo/{strapiArticles.slug}.tsx" /* webpackChunkName: "component---src-pages-farnost-nase-spolocenstvo-strapi-articles-slug-tsx" */),
  "component---src-pages-farnost-virtualna-prehliadka-tsx": () => import("./../../../src/pages/farnost/virtualna-prehliadka.tsx" /* webpackChunkName: "component---src-pages-farnost-virtualna-prehliadka-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-objav-krista-tsx": () => import("./../../../src/pages/objav-krista.tsx" /* webpackChunkName: "component---src-pages-objav-krista-tsx" */),
  "component---src-pages-prispevky-strapi-post-slug-tsx": () => import("./../../../src/pages/prispevky/{StrapiPost.slug}.tsx" /* webpackChunkName: "component---src-pages-prispevky-strapi-post-slug-tsx" */),
  "component---src-pages-spolocenstva-akolyti-tsx": () => import("./../../../src/pages/spolocenstva/akolyti.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-akolyti-tsx" */),
  "component---src-pages-spolocenstva-detsky-spevokol-tsx": () => import("./../../../src/pages/spolocenstva/detsky-spevokol.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-detsky-spevokol-tsx" */),
  "component---src-pages-spolocenstva-lektori-tsx": () => import("./../../../src/pages/spolocenstva/lektori.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-lektori-tsx" */),
  "component---src-pages-spolocenstva-ministranti-tsx": () => import("./../../../src/pages/spolocenstva/ministranti.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-ministranti-tsx" */),
  "component---src-pages-spolocenstva-mladeznicky-zbor-tsx": () => import("./../../../src/pages/spolocenstva/mladeznicky-zbor.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-mladeznicky-zbor-tsx" */),
  "component---src-pages-spolocenstva-reholne-sestry-tsx": () => import("./../../../src/pages/spolocenstva/reholne-sestry.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-reholne-sestry-tsx" */),
  "component---src-pages-spolocenstva-ruzencove-bratstvo-tsx": () => import("./../../../src/pages/spolocenstva/ruzencove-bratstvo.tsx" /* webpackChunkName: "component---src-pages-spolocenstva-ruzencove-bratstvo-tsx" */),
  "component---src-pages-sviatosti-sviatost-birmovania-tsx": () => import("./../../../src/pages/sviatosti/sviatost-birmovania.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-birmovania-tsx" */),
  "component---src-pages-sviatosti-sviatost-eucharistie-tsx": () => import("./../../../src/pages/sviatosti/sviatost-eucharistie.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-eucharistie-tsx" */),
  "component---src-pages-sviatosti-sviatost-krstu-tsx": () => import("./../../../src/pages/sviatosti/sviatost-krstu.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-krstu-tsx" */),
  "component---src-pages-sviatosti-sviatost-manzelstva-tsx": () => import("./../../../src/pages/sviatosti/sviatost-manzelstva.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-manzelstva-tsx" */),
  "component---src-pages-sviatosti-sviatost-pomazania-chorych-tsx": () => import("./../../../src/pages/sviatosti/sviatost-pomazania-chorych.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-pomazania-chorych-tsx" */),
  "component---src-pages-sviatosti-sviatost-zmierenia-tsx": () => import("./../../../src/pages/sviatosti/sviatost-zmierenia.tsx" /* webpackChunkName: "component---src-pages-sviatosti-sviatost-zmierenia-tsx" */),
  "component---src-pages-vyveska-strapi-announcements-slug-tsx": () => import("./../../../src/pages/vyveska/{StrapiAnnouncements.slug}.tsx" /* webpackChunkName: "component---src-pages-vyveska-strapi-announcements-slug-tsx" */)
}

